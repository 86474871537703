import React from 'react';
import BottomModal from './BottomModal';

function BottomCode(props) {

  return (
    <div className="bottom-code">
			<BottomModal open={ props.popup } hidePopup={ ()=>{ props.setPopup(false) } }>
        <div className="popup-inner popup-activities popup-code">
					<h2>Twój numer BAT Partnera</h2>
          <p>Numer ten jest wymagany przy rejestracji urządzenia na stronie <a href="http://batpartners.pl/konsument">batpartners.pl/konsument</a>.</p>
          <div className="dark-pane">
            <div className="bat-code">{ props.user.user ? props.user.user.referralCode : "" }</div>
          </div>
				</div>
      </BottomModal>
    </div>
  );
}

export default BottomCode;
