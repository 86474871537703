import { combineReducers } from 'redux'
import toastsReducer from "../reducers/toasts";
import screenReducer from "../reducers/screen";
import userReducer from "../reducers/user";
import accountReducer from "../reducers/account";
import activationsReducer from "../reducers/activations";

export default (history) => combineReducers({
  toasts: toastsReducer,
  screen: screenReducer,
  user: userReducer,
  account: accountReducer,
  activations: activationsReducer
})