import { 
  LOGIN,
  LOGOUT,
  SESSION,
  REQUEST_RESET_PASS,
  RESET_PASS,
  REMIND,
  ADD_TOAST,
  SET_TOKEN,
  SET_OTP_TOKEN,
  REGISTER_SUCCESS,
  REGISTER_SUCCESS_CLEAR,
  WELCOME_SCREEN_SHOWN,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  ON_LOGIN_POPUP_OPEN,
  ON_LOGIN_POPUP_CLOSE
} from './types';
import { API_URL, appFetchBasic, appFetch } from '../helpers/fetch';
import { errors } from '../helpers/errors';
import base64 from 'base-64';

const URL = window.location.hostname;

export function login(data, history) {
const token = base64.encode(data.username+':'+data.password);
return (dispatch, getState) => { 
  
    appFetchBasic("POST", API_URL, "/burner/login", null, token, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: LOGIN, payload: { username: data.username, password: data.password} });
            history.replace("/dashboard")
            dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Zalogowano" } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
       dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
    
}
}

export function setToken(token) {
return (dispatch, getState) => { 
  dispatch({ type: SET_TOKEN, payload: { token: token } });
}
}

export function register(data, history) {
return (dispatch, getState) => { 
    const rdata = {
      user: data,
      reset: {
        return: {
          url: 'https://'+URL+"/?reset="
        }
      }
    }
    appFetch("POST", API_URL, "/user/register", rdata, false, (result) => {
        if(result.status && result.status.success) {
            //history.replace("/rejestracja-udana")
            dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Rejestracja udana" } });
            dispatch({ type: REGISTER_SUCCESS, payload: { } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
       dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
    
}
}

export function checkSession(history, redirect, token) {
return (dispatch, getState) => { 
  const currentState = getState()
  const token = base64.encode(currentState.user.username+':'+currentState.user.password);
    appFetchBasic("POST", API_URL, "/burner/login", false, token, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: SESSION, payload: {  } });
            if(redirect) {
              if(history && history.location.pathname === '/') {
                history.replace("/dashboard")
              }
            }
        } else {
            dispatch({ type: SESSION, payload: { token: null, user: null } });
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
            history.replace("/")
        }
    }, (error) => {
       dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
}
}

export function logout(history) {
return (dispatch, getState) => { 
  const currentState = getState()
  dispatch({ type: LOGOUT, payload: null });
  history.replace("/")
}
}

export function remind(data, history) {
return (dispatch, getState) => { 
  const currentState = getState()
  appFetch("POST", API_URL, "/user/reset-token", data, currentState.user.token ? currentState.user.token : false, (result) => {
    if(result.status && result.status.success) {
        dispatch({ type: REMIND, payload: data.username });
        history.replace("/remind/code")
    } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        history.replace("/remind")
    }
  }, (error) => {
    dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
  })
}
}

export function requestResetPass(email) {
return (dispatch, getState) => {
  const currentState = getState()
  const data = {
      "user": {
          "emailAddress": email
      },
      "reset": {
          "return": {
              "url": 'https://'+URL+"/?reset="
          }
      }
  }

  appFetch("POST", API_URL, "/user/request-reset-password", data, false, (result) => {
    if(result.status && result.status.success) {
        dispatch({ type: REQUEST_RESET_PASS, payload: null });
        //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Zmieniono hasło." } });
    } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
    }
  }, (error) => {
    dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
  })
}
}

export function resetPass(data, history) {
return (dispatch, getState) => { 
  const currentState = getState()
  appFetch("POST", API_URL, "/user/reset-password", data, currentState.user.reset_token ? currentState.user.reset_token : false, (result) => {
    if(result.status && result.status.success) {
        dispatch({ type: RESET_PASS, payload: null });
        history.replace("/");
        dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Ustawiono nowe hasło. Możesz się zalogować." } });
    } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
    }
  }, (error) => {
    dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
  })
}
}

export function otp() {
return (dispatch, getState) => { 
  const currentState = getState()
  const data = {
    otp: {
        purpose: "TOKEN_PARTIAL_UPDATE"
    }
  }
  appFetch("POST", API_URL, "/user/otp", data, currentState.user.token ? currentState.user.token : false, (result) => {
    if(result.status && result.status.success) {
        dispatch({ type: SET_OTP_TOKEN, payload: {} });
        dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Kod wysłany" } });
    } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
    }
  }, (error) => {
    dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
  })
}
}

export function updateUser(data) {
return (dispatch, getState) => { 
  const currentState = getState()
  appFetch("POST", API_URL, "/user/partial-update", data, currentState.user.token ? currentState.user.token : false, (result) => {
    if(result.status && result.status.success) {
      dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Dane zostały zapisane" } });
      dispatch(checkSession())
    } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
    }
  }, (error) => {
    dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
  })
}
}

export function clearFlags() {
return (dispatch, getState) => { 
  dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {  } });
}
}

export function messageSeen(msg) {
return (dispatch, getState) => { 
  const currentState = getState();
  const message = getState().user.messages.filter((item, key)=>{
    return item.ident === msg
  })

  const data = { message: {
    uuid: message[0].uuid
  }}

  dispatch({ type: WELCOME_SCREEN_SHOWN, payload: {  } });
  appFetch("POST", API_URL, "/message/seen", data, currentState.user.token ? currentState.user.token : false, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: GET_MESSAGES_SUCCESS, payload: { messages: result.data.messages } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error) => {
    dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
  })
}
}

export function getMessages() {
return (dispatch, getState) => { 
  const currentState = getState();
  dispatch({ type: GET_MESSAGES, payload: {  } });
  appFetch("POST", API_URL, "/message/list", false, currentState.user.token ? currentState.user.token : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: GET_MESSAGES_SUCCESS, payload: { messages: result.data.messages } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error) => {
    dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
  })
}
}

export function onLoginPopup(popupState) {
return (dispatch, getState) => { 
  if (popupState === true) {
    dispatch({ type: ON_LOGIN_POPUP_OPEN, payload: {  } });
  } else {
    dispatch({ type: ON_LOGIN_POPUP_CLOSE, payload: {  } });
  }
}
}
