import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { ReactComponent as IconClose } from '../../../img/icon-close.svg'

class Kontakt extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { activeQuestion: null };
  }

  render() {
    return (
      <div className="page kontakt">
        <section>
          <div className="page-header">
            <h1>Kontakt</h1>
          </div>
          <p>W przypadku pytań, wyślij nam wiadomość na adres <a href="mailto:kontakt@batpartners.pl" className="big bold">kontakt@batpartners.pl</a> – pomożemy!</p>
          <p><strong>Infolinia</strong><br/> <span className="bold color-blue">800 610 610*</span></p>
          <p>Zgłoszenia dotyczące danych osobowych <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a></p>
          <p className="small">*Bezpłatna infolinia jest czynna od poniedziałku do niedzieli w godz. 8:00- 22:00.</p>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    
  }
)(Kontakt));