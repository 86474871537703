import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../components/Helpers";
import { NavLink } from 'react-router-dom';
import { logout } from '../actions/user';
import { ReactComponent as IconClose } from '../../img/icon-close.svg'

class Navigation extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { };
  }

  render() {
    
    return (
      <div className={this.props.open ? "nav active" : "nav" }>
        <div className="close" onClick={ this.props.close }><IconClose /></div>
        <div className="nav-links">
          <NavLink className="nav-item" exact to="/kontakt" onClick={ this.props.close }><span>Kontakt</span></NavLink>
        </div>
        <div className="nav-footer">
          <span className="nav-item" onClick={ () => { this.props.close(); this.props.logout(this.props.history) } }><span>Wyloguj</span></span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    logout
  }
)(Navigation));