export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('state');

		if (serializedState === null) {
			return { 
				user: {
					user: {
						firstname: '',
						lastname: ''
					},
					welcomeShow: false,
					messages: []
				},
				account: {
					balance: '-',
					profit: '-',
					devicesSold: '-',
					incomeHistory: [],
					payoutHistory: [],
					seasons: [
						{
							soldDevices: { all: 0, hyper: 0, vuse: 0 }
						}
					],
					soldDevices: { all: 0, hyper: 0, vuse: 0 }
				},
				activations: {
					ranking: [],
					rankingConsumer: [],
					bottomError: {show: false, message: ''},
					payout: ''
				}
			}
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return { 
			user: {
				user: {
					firstname: '',
					lastname: '',
				},
				messages: [],
				welcomeShow: false
			},
			account: {
				balance: '-',
				profit: '-',
				devicesSold: '-',
				incomeHistory: [],
				payoutHistory: [],
				seasons: [
					{
						soldDevices: { all: 0, hyper: 0, vuse: 0 }
					}
				],
				soldDevices: { all: 0, hyper: 0, vuse: 0 }
			},
			activations: {
				ranking: [],
				rankingConsumer: [],
				bottomError: {show: false, message: ''},
				payout: ''
			}
		}
	}
}

export const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (err) {
		// errors
	}
}

export const clearState = () => {
	try {
		//console.log(localStorage.getItem('state'))
		localStorage.removeItem('state');
		//console.log(localStorage.getItem('state'))
		//console.log('clear state')
	} catch (err) {
		// errors
		console.log(err)
	}
}