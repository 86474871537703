import React from 'react';
import { connect } from "react-redux";
import {
  Switch,
  Route
} from "react-router-dom";
import BaseComponent from "../components/Helpers";
import Header from "../components/Header"
import { saveDimensionData } from '../actions/screen';
import { withRouter } from 'react-router-dom';
import Toasts from '../components/toasts/Toasts';
import LoginPage from '../components/pages/LoginPage';

import Dashboard from '../components/pages/Dashboard';
import NewSale from '../components/pages/NewSale';
import Navigation from '../components/Navigation';
import BottomNav from '../components/BottomNav';

import { checkSession, setToken, onLoginPopup } from '../actions/user';
import { ReactComponent as ColorLine } from '../../img/kreski.svg'
import BottomCode from '../components/BottomCode';
import BottomError from '../components/BottomError';
import BottomInfo from '../components/BottomInfo';
import { hideBottomError } from '../actions/activations';
import Regulamin from '../components/pages/Regulamin';
import Kontakt from '../components/pages/Kontakt';


class App extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { navOpen: false, bottomPopup: false, bottomCodePopup: false };
  }

  componentWillMount() {
    this.updateDimensions();
    //this.props.checkSession(this.props.history, true)
    if (this.props.location.search.indexOf("reset") !== -1 ) {
      let token = this.props.location.search.split("=")[1];
      this.props.setToken(token);
      this.props.history.push("/nowe-haslo", { from: this.props.location })
    } else {
      this.props.checkSession(this.props.history, true)
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.props.saveDimensionData({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }

  render() {

    return (
      <>
      <div className="app">
        <ColorLine className="color-line" preserveAspectRatio="none"/>
        { (this.props.location.pathname != '/' && this.props.location.pathname != '/remind' && this.props.location.pathname != '/register' && this.props.location.pathname != '/nowe-haslo') ? <Header location={ this.props.location } navOpen={ (open) => { this.setState({ navOpen: open }) }} /> : null }
        <Navigation open={this.state.navOpen} close={ ()=>{ this.setState({ navOpen: false }) }} />
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/`} exact>
            <LoginPage/>
          </Route>
          
          <Route path={`${process.env.PUBLIC_URL}/dashboard`} exact>
            <Dashboard setPopup={ (popupState)=>{ this.setState({ bottomCodePopup: popupState }) } } />
          </Route>

          <Route path={`${process.env.PUBLIC_URL}/nowa-sprzedaz`} exact>
            <NewSale setPopup={ (popupState)=>{ this.setState({ bottomCodePopup: popupState }) } }  />
          </Route>

          <Route path={`${process.env.PUBLIC_URL}/regulamin`} exact>
            <Regulamin/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/kontakt`} exact>
            <Kontakt/>
          </Route>
          
        </Switch>
        { (this.props.location.pathname != '/' && this.props.location.pathname != '/remind' && this.props.location.pathname != '/register' && this.props.location.pathname != '/nowa-sprzedaz' && this.props.location.pathname != '/nowe-haslo') ? <BottomNav popup={ this.state.bottomPopup } setPopup={ (popupState)=>{ this.setState({ bottomPopup: popupState }) } } /> : null }
        <BottomCode popup={ this.state.bottomCodePopup } setPopup={ (popupState)=>{ this.setState({ bottomCodePopup: popupState }) } } user={ this.props.user } />
        <BottomError popup={ this.props.activations.bottomError.show } message={ this.props.activations.bottomError.message } setPopup={ this.props.hideBottomError } />
        <BottomInfo popup={ this.props.user.onLoginPopup } setPopup={ (popupState)=>{ this.props.onLoginPopup(popupState) } } />
        <Toasts/>
      </div>
    </>
    )
  }
}

const mapStateToProps = (state) => {
  const { screen, user, activations } = state
  return { screen, user, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {
    saveDimensionData,
    checkSession,
    setToken,
    hideBottomError,
    onLoginPopup
  }
)(App));