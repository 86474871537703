import React from 'react';
import BottomModal from './BottomModal';
import { ReactComponent as IconPrize } from '../../img/icon-prize.svg';
import { NavLink } from "react-router-dom";

function BottomInfo(props) {

  return (
    <div className="bottom-code">
			<BottomModal open={ props.popup } hidePopup={ ()=>{ props.setPopup(false) } }>
        <div className="popup-inner popup-info">
          <IconPrize/>
          <h2>Ranking poleceń!</h2>
          <p>Polecaj Konsumentom rejestrację na stronie <a href="http://batpartners.pl/konsument">batpartners.pl/konsument</a> i&nbsp;walcz o czołowe miejsca w nowym Rankingu Poleceń.</p>
          <p>Zarejestrowany Konsument z Twoim kodem przybliża Cię do dodatkowej nagrody, a my nagradzamy go kodem SMS na 3 wkłady w cenie 1!</p>
          <NavLink className="btn" to="/ranking" onClick={ () => { props.setPopup(false) }} >Sprawdź swoją pozycję</NavLink>
        </div>
      </BottomModal>
    </div>
  );
}

export default BottomInfo;
