import { suppressDeprecationWarnings } from "moment";
import {
    ACCOUNT_BALANCE,
    ACCOUNT_PAYMENT_CREATE,
    ACCOUNT_PAYMENT_REQUEST
  } from "../actions/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case ACCOUNT_BALANCE:
        const countProfit = () => {
          let profit = 0;
          action.payload.balance.history.forEach((item, key) => {
            if (item.value > 0) {
              profit += item.value;
            }
          })
          return profit;
        }

        const payoutHistory = action.payload.balance.history.filter((item, key)=>{
          return item.value < 0;
        })

        const incomeHistory = action.payload.balance.history.filter((item, key)=>{
          return item.value > 0;
        })

        const quarters = ['q2_21','q1_21']

        const seasons = quarters.map((name) => {

          const countDevices = (type) => {
            let sold = 0;

            const activations = action.payload.balance.history.filter( (item)=>{
              return item.quarter === name;
            })

            activations.forEach((item, key) => {
              if (item.solution && item.solution.activation.ident === 'device_register') {
                if (type === "hyper" && item.solution.payload.model === "HYPER") {
                  sold += 1;
                } else if (type === "vuse" && (!item.solution.payload.model || (item.solution.payload.model && item.solution.payload.model != "HYPER"))) {
                  sold += 1;
                } else if ( type === 'all' ) {
                  sold += 1;
                } 
              }
            })
            return sold;
          }

          const rankId = (dv) => {
            if (dv <=2) {
              return 0
            } else if ( dv > 2 && dv <= 5) {
              return 1
            } else if ( dv > 5 && dv <= 9) {
              return 2
            } else if ( dv > 9 && dv <= 14) {
              return 3
            } else if ( dv > 14 && dv <= 19) {
              return 4
            } else {
              return 5
            }
          }

          const rankNext = (dv) => {
            const names = ["zero", "jednego", "dwóch", "trzech", "czterech", "pięciu", "sześciu", "siedmiu", "ośmiu"];

            if (dv <=2) {
              const a = 3-dv;
              return names[a]
            } else if ( dv > 2 && dv <= 4) {
              const a = 5-dv;
              return names[a]
            } else if ( dv > 4 && dv <= 9) {
              const a = 10-dv;
              return names[a]
            } else if ( dv > 9 && dv <= 14) {
              const a = 15-dv;
              return names[a]
            } else {
              return names[0]
            }
          }

          return (
            {
              soldDevices: {
                all: countDevices('all'),
                hyper: countDevices('hyper'),
                vuse: countDevices('vuse')
              },
              rankId: rankId(countDevices('all')),
              rankNext: rankNext(countDevices('all'))
            }
          )
        })

        return {
          ...state,
          data: action.payload.balance,
          balance: action.payload.balance.value,
          profit: countProfit(),
          payoutHistory: payoutHistory,
          incomeHistory: incomeHistory,
          seasons: seasons
        }
      case ACCOUNT_PAYMENT_REQUEST:
        return {
          ...state,
          paymentInProgress: true
        }
      case ACCOUNT_PAYMENT_CREATE:
        return {
          ...state,
          paymentInProgress: false
        }
      default:
        return state;
    }
  }