import { 
  ACCOUNT_BALANCE,
  ACCOUNT_PAYMENT_CREATE,
  ACCOUNT_PAYMENT_REQUEST,
  ADD_TOAST,
  REGISTER_SUCCESS_CLEAR
} from './types';
import { API_URL, appFetch } from '../helpers/fetch';
import { errors } from '../helpers/errors';

export function accountBalance() {
  return (dispatch, getState) => { 
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {  } });
    appFetch("POST", API_URL, "/user/account/balance", false, currentState.user.token ? currentState.user.token : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: ACCOUNT_BALANCE, payload: { balance: result.data.balance } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function payout() {
  return (dispatch, getState) => { 
    const currentState = getState();
    dispatch({ type: ACCOUNT_PAYMENT_REQUEST, payload: {  }});
    appFetch("POST", API_URL, "/user/account/payment-create", false, currentState.user.token ? currentState.user.token : false, (result) => {
      dispatch({ type: ACCOUNT_PAYMENT_CREATE, payload: {paymentStatus: result.data } });
      if(result.status && result.status.success) {
          dispatch(accountBalance());
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Twoja wypłata jest procesowana" } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}