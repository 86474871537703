import {
  REGISTER_DEVICE,
  REGISTER_SUCCESS_CLEAR,
  QUIZ_END,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  GET_RANKING,
  GET_RANKING_CONSUMER,
  BOTTOM_ERROR, GET_ACTIVATION_LIST, REGISTER_DEVICE_LIMIT, SET_STEP, BURN_DEVICE
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case REGISTER_DEVICE:
      return {
        ...state,
        registerSuccess: true,
        payout: action.payload,
        deviceLimit: false,
        fileIdent: ""
      }
    case REGISTER_DEVICE_LIMIT:
        return {
          ...state,
          registerSuccess: false,
          deviceLimit: true
        }
    case QUIZ_END:
      return {
        ...state,
        quizSuccess: true
      }
    
    case REGISTER_SUCCESS_CLEAR:
      return {
        ...state,
        registerSuccess: false,
        quizSuccess: false,
        ocrSuccess: false,
        deviceLimit: false
      }

    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        ocrSuccess: false
      }

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        ocrSuccess: true,
        serial: action.payload.serial,
        fileIdent: action.payload.fileIdent
      }

    case GET_RANKING:
      return {
        ...state,
        ranking: action.payload.ranking
      }
    
    case GET_RANKING_CONSUMER:
      return {
        ...state,
        rankingConsumer: action.payload.ranking
      }

    case BOTTOM_ERROR:
      return {
        ...state,
        bottomError: action.payload
      }
    
    case GET_ACTIVATION_LIST:
      return {
        ...state,
        list: action.payload.list
      }

    case SET_STEP:
      return {
        ...state,
        step: action.payload.step,
        registerSuccess: false,
        quizSuccess: false,
        ocrSuccess: false,
        deviceLimit: false
      }
    
    case BURN_DEVICE:
      return {
        ...state,
        registerSuccess: true,
        payout: action.payload,
        deviceLimit: false,
        fileIdent: ""
      }

    default:
      return state;
  }
}