export const errors = {
    "generic": "Wystąpił błąd",
    "error_unauthorized": "Nie zalogowano, lub sesja wygasła.",
    "error_token_expired": "Sesja wygasła",
    "error_user_not_found": "Użytkownik nie istnieje",
    "error_user_wrong_password": "Błędne hasło",
    "error_user_empty_password": "error_user_empty_password",
    "error_awaiting_otp": "error_awaiting_otp",
    "error_wrong_otp": "Błędny kod sms",
    "error_expired_otp": "Kod sms wygasł",
    "error_daily_otp_limit_reached": "Wykorzystałeś limit zmian. Spróbuj ponownie jutro.",
    "error_empty_token_list": "error_empty_token_list",
    "error_not_confirmed": "error_not_confirmed",
    "error_email_address_taken": "Adres email zajęty",
    "error_mobile_phone_taken": "Numer telefonu zajęty",
    "error_username_taken": "Nazwa użytkownika zajęta",
    "error_role_not_found": "error_role_not_found",
    "error_http_exception": "Wystąpił błąd",
    "error_device_not_found": "Nieprawidłowy numer urządzenia",
    "error_zero_results": "Nie udało się wykryć prawidłowego numeru. Spróbuj ponownie.",
    "error_too_low_balance_value": "Dokonaj pierwszej sprzedaży i odbierz swoją nagrodę. Wypłata środków, zgodnie z regulaminem możesz dokonać po sprzedaży pierwszego urządzenia.",
    "error_amount_of_registered_devices_reached": "Wykorzystałeś limit rejestracji. Spróbuj ponownie jutro."
}