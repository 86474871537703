import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { login, clearFlags } from '../../actions/user';
import { NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Button from '../Button'

function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    props.clearFlags();
  }, [])

  let validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz nazwę użytkownika")
    
    } else if (pass === "") {
      setError("Wpisz hasło")
    } else {
      setError("")
      login()
    }
  }

  let login = () => {
    console.log(email,pass)
    let data = {
      username: email,
      password: pass
    }
    
    props.login(data, history)
  }

  return (
    <section className="login-page">
    	<div className="page-inner">
  			<h1>Witaj w programie <br/>BAT Partners</h1>
  			<p className="color-orange bold">Zaloguj się</p>
  			<form>
          <div className="input-item">
            <label htmlFor="email">Nazwa użytkownika</label>
            <input type="text" id="email" name="email" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
          </div>
          <div className="input-item">
            <label htmlFor="password">Hasło</label>
            <input type="password" id="password" name="password" value={ pass } onChange={ (e) => { setPass(e.target.value) } }/>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <div className="">
            <Button className="btn" action={ (e) => { validate(e) } }>Zaloguj</Button>
          </div>
        </form>
  		</div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    login,
    clearFlags
  }
)(LoginPage));