import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { accountBalance } from '../../actions/account';
import { listActivations, listRanking, showBottomError, listConsumerRanking, setStep } from '../../actions/activations';
import { messageSeen, getMessages } from '../../actions/user';
import { ReactComponent as IconClose } from '../../../img/icon-close.svg'
import { ReactComponent as IconMoney } from '../../../img/icon-money.svg'
import { ReactComponent as IconPodium } from '../../../img/icon-podium.svg'
import { ReactComponent as IconStar } from '../../../img/icon-star.svg'
import { ReactComponent as ProgressBar } from '../../../img/progress_bar_line.svg'

class Dashboard extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { welcomeStep: 0 };
    //this._bind('openActionPopup', 'hideActionPopup');
  }

  componentWillMount() {
    //this.props.accountBalance();
    //this.props.getMessages();
    //this.props.listActivations();
    //this.props.listRanking(0);
    //this.props.listConsumerRanking(0);
    this.props.setStep(0)
  }

  render() {
    let step = null;
    
    let soldTmp = this.props.account.seasons[0].soldDevices.all;
    if ( soldTmp > 20) {
      soldTmp = 20;
    }
    let lineOffset = 492 - (492/20)*soldTmp;

    return (
      <div className="page dashboard">
        {/*<div className="season-info">
          Zakończ 1 sezon z przytupem. <br/>
          Masz czas do 30 kwietnia!
        </div> */}
        <section>
          <p className="welcome">Witaj<br/>
            <span className="profile-name">{ this.props.user.username ? this.props.user.username : "" }</span><br/>
            </p>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user, account } = state
  return { screen, user, account }
};

export default withRouter(connect(
  mapStateToProps,
  {
    accountBalance,
    listActivations,
    messageSeen,
    listRanking,
    listConsumerRanking,
    showBottomError,
    getMessages,
    setStep
  }
)(Dashboard));