import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { ReactComponent as IconClose } from '../../../img/icon-close.svg'

class Regulamin extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {  };
  }

  componentWillMount() {
    window.location.href = 'https://batpartners.pl/assets/regulamin_bat_partners_2021_210409.pdf';
  }

  render() {
    return (
      <div className="page faq">
        <section>
          <div className="page-header">
            <h1>Regulamin</h1>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    
  }
)(Regulamin));