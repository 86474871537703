import React, { useState } from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import { ReactComponent as IconActivities } from '../../img/icon-activities-white.svg'
import { ReactComponent as IconDashboard } from '../../img/icon-dashboard-white.svg'
import { ReactComponent as IconClose } from '../../img/icon-close-primary.svg'

function BottomNav(props) {

	let checkCompleted = (type) => {
		if (props.activations.list) {
			let activation = props.activations.list.filter((item, key) => {
				return item.ident === type
			})

			if (activation.length > 0 && activation[0].currentNumberOfSolutions === 1) {
				return 'active';
			} else {
				return '';
			}
		} else {
			return '';
		}
	}

	let getQuarter = () => {
		let today = new Date();
		let quarter = Math.floor((today.getMonth() / 3)) + 1;
		let year = today.getFullYear().toString().substr(-2);
		return `q${quarter}_${year}`;
	}

  return (
    <div className="bottom-nav">
    	<ul>
    		<li>
    			<div className="bottom-nav-item" >

    			</div>
    		</li>
    		<li>
    			<NavLink to="/nowa-sprzedaz">
    				<div className="new-sale-icon"></div>
    				<span>Spal kody</span>
    			</NavLink>
    		</li>
    		<li>
    			<a>
    			</a>
    		</li>
    	</ul>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, activations } = state
  return { screen, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {
    
  }
)(BottomNav));
