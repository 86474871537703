//toasts
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

// screen
export const SCREEN_DIMENSIONS_SAVE = "SCREEN_DIMENSIONS_SAVE";

// routing
export const NAVIGATE_HOME = "NAVIGATE_HOME";

// user
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REMIND = "REMIND";
export const REMIND_OTP = "REMIND_OTP";
export const SESSION = "SESSION";
export const RESET_PASS = "RESET_PASS";
export const REQUEST_RESET_PASS = "REQUEST_RESET_PASS";
export const SET_TOKEN = "SET_TOKEN";
export const SET_OTP_TOKEN = "SET_OTP_TOKEN";
export const REGISTER_SUCCESS = "REGISTER_SUCCES";
export const REGISTER_SUCCESS_CLEAR = "REGISTER_SUCCESS_CLEAR";
export const REMIND_SUCCESS = "REMIND_SUCCES";

export const ACCOUNT_BALANCE = "ACCOUNT_BALANCE";
export const ACCOUNT_PAYMENT_REQUEST = "ACCOUNT_PAYMENT_REQUEST";
export const ACCOUNT_PAYMENT_CREATE = "ACCOUNT_PAYMENT_CREATE";

export const GET_ACTIVATION_LIST = "GET_ACTIVATION_LIST";
export const REGISTER_DEVICE = "REGISTER_DEVICE";
export const REGISTER_DEVICE_LIMIT = "REGISTER_DEVICE_LIMIT";
export const SUBMIT_QUIZ = "SUBMIT_QUIZ";
export const QUIZ_END = "QUIZ_END";
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const BOTTOM_ERROR = "BOTTOM_ERROR";

export const GET_RANKING = "GET_RANKING";
export const GET_RANKING_CONSUMER = "GET_RANKING_CONSUMER";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";

// pages ///////////////////////////////////

export const WELCOME_SCREEN_SHOWN = "WELCOME_SCREEN_SHOWN";
export const ON_LOGIN_POPUP_OPEN = "ON_LOGIN_POPUP_OPEN";
export const ON_LOGIN_POPUP_CLOSE = "ON_LOGIN_POPUP_CLOSE";

export const SET_STEP = "SET_STEP";

export const BURN_DEVICE = "BURN_DEVICE";