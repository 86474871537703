import { 
  GET_ACTIVATION_LIST,
  REGISTER_DEVICE,
  REGISTER_DEVICE_LIMIT,
  SUBMIT_QUIZ,
  ADD_TOAST,
  QUIZ_END,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  GET_RANKING,
  GET_RANKING_CONSUMER,
  BOTTOM_ERROR,
  SET_STEP,
  BURN_DEVICE
} from './types';
import { API_URL, appFetch, fileFetch, appFetchBasic, fileFetchBasic } from '../helpers/fetch';
import { errors } from '../helpers/errors';
import base64 from 'base-64';

export function listActivations() {
  return (dispatch, getState) => {
    const currentState = getState();
    appFetch("POST", API_URL, "/activation/list", false, currentState.user.token ? currentState.user.token : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: GET_ACTIVATION_LIST, payload: { list: result.data.activations } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function registerDevice(device) {
  
  return (dispatch, getState) => {
    const currentState = getState();
    const deviceNo = device.length > 0 ? device : currentState.activations.serial;
    const data = {
        activation: {
            ident: "device_register",
            payload: {
                device: {
                    "serialNumber": deviceNo
                }
            }
        },
        file: {
          ident: currentState.activations.fileIdent || ""
        }
    }
    appFetch("POST", API_URL, "/activation/solution/create", data, currentState.user.token ? currentState.user.token : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: REGISTER_DEVICE, payload: result.data.payout });
        } else {
          if (result.data.error === "error_amount_of_registered_devices_reached") {
            dispatch({ type: REGISTER_DEVICE_LIMIT, payload: { } });
          } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : errors.generic } });
            dispatch({ type: BOTTOM_ERROR, payload: { show: true, message: errors[result.data.error] || result.data.error } });

          }
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function uploadFile(file) {

  const fd = new FormData();
  fd.append('files[]', file);
  return (dispatch, getState) => {
    const currentState = getState();

    dispatch({ type: UPLOAD_FILE_REQUEST, payload: {  } });

    fileFetch("POST", API_URL, "/ocr/extract-identify", fd, currentState.user.token ? currentState.user.token : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: UPLOAD_FILE_SUCCESS, payload: { serial: result.data.devices[0][0], fileIdent: result.data.file.ident } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
            dispatch({ type: BOTTOM_ERROR, payload: { show: true, message: result.data.error } });
            dispatch({ type: SET_STEP, payload: { step: 0 } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function quiz(answers) {
  const data = {
      activation: {
          ident: "quiz_start",
          payload: answers
      }
  }
  return (dispatch, getState) => {
    const currentState = getState();
    appFetch("POST", API_URL, "/activation/solution/create", data, currentState.user.token ? currentState.user.token : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: QUIZ_END, payload: {  } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function listRanking(delta) {
  return (dispatch, getState) => {
    const currentState = getState();
    appFetch("POST", API_URL, "/activation/ranking", {delta: delta}, currentState.user.token ? currentState.user.token : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: GET_RANKING, payload: { ranking: result.data.device_register } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function listConsumerRanking(delta) {
  return (dispatch, getState) => {
    const currentState = getState();
    appFetch("POST", API_URL, "/activation/ranking-consumer", {delta: delta}, currentState.user.token ? currentState.user.token : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: GET_RANKING_CONSUMER, payload: { ranking: result.data } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function hideBottomError() {

  return (dispatch, getState) => {
    const currentState = getState();

    dispatch({ type: BOTTOM_ERROR, payload: { show: false, message: currentState.activations.bottomError.message } });
  }
}

// testing only
export function showBottomError() {
  return (dispatch, getState) => {
    const currentState = getState();

    dispatch({ type: BOTTOM_ERROR, payload: { show: true, message: 'error_wrong_serial_number_limit_exceeded' } });
  }
}

export function setStep(step) {
  return (dispatch, getState) => {

    dispatch({ type: SET_STEP, payload: { step: step } });
  }
}

export function burn(device) {
  
  return (dispatch, getState) => {
    const currentState = getState();
    const deviceNo = device.length > 0 ? device : currentState.activations.serial;
    const token = base64.encode(currentState.user.username+':'+currentState.user.password);
    const data = {
        device: {
            "serialNumber": deviceNo
        },
        file: {
          ident: currentState.activations.fileIdent || ""
        }
    }
    appFetchBasic("POST", API_URL, "/burner/burn", data, token, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: BURN_DEVICE, payload: result.data.payout });
        } else {
          if (result.data.error === "error_amount_of_registered_devices_reached") {
            dispatch({ type: REGISTER_DEVICE_LIMIT, payload: { } });
          } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : errors.generic } });
            dispatch({ type: BOTTOM_ERROR, payload: { show: true, message: errors[result.data.error] || result.data.error } });

          }
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function uploadBurnFile(file) {
  const fd = new FormData();
  fd.append('files[]', file);
  
  return (dispatch, getState) => {
    const currentState = getState();
    const token = base64.encode(currentState.user.username+':'+currentState.user.password);
    
    dispatch({ type: UPLOAD_FILE_REQUEST, payload: {  } });

    fileFetchBasic("POST", API_URL, "/burner/extract-identify", fd, token, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: UPLOAD_FILE_SUCCESS, payload: { serial: result.data.devices[0][0], fileIdent: result.data.file.ident } });
        } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
            dispatch({ type: BOTTOM_ERROR, payload: { show: true, message: result.data.error } });
            dispatch({ type: SET_STEP, payload: { step: 0 } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}